<template>
  <div id="app">
    <!-- 一级路由 -->
    <router-view></router-view>
    <!-- 登录弹框 -->
    <transition name="el-fade-in-linear">
      <div class="login_cover" v-if="showLoginStatus">
        <Login ref="loginRef"></Login>
      </div>
    </transition>
  </div>
</template>

<script>
import Login from '@/views/Login/login.vue'
export default {
  name: 'App',
  components: {
    Login
  },
  computed: {
    showLoginStatus() {
      return this.$store.state.showLoginStatus
    }
  },
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>
<style scoped lang="scss">
.login_cover {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: PingFang SC, Microsoft YaHei, Hiragino Sans GB, Helvetica, Tahoma,
    Arial;
  text-decoration: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  display: block;
  left: 0;
  top: 0;
  font-size: 12px;
}
</style>
